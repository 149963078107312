import queryClient from '#/api/query-client';
import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import {
  ContractParties,
  IAsset,
  IAssetsList,
  IPropertiesList,
  IProperty,
} from '#/types/marriageContract';

const baseURL = '/services/marriage-contract';

export const MarriageContractEndpoints = {
  getAssets: () => `${baseURL}/estate-contracts/`,
  addAsset: () => `${baseURL}/estate-contracts/`,
  editAsset: (id: number | string | undefined) =>
    `${baseURL}/estate-contracts/${id}/`,
  deleteAsset: (id: number | string | undefined) =>
    `${baseURL}/estate-contracts/${id}/`,
  getContractParties: () => `${baseURL}/contract-parties/`,
  getProperties: () => `${baseURL}/properties/`,
  addProperty: () => `${baseURL}/properties/`,
  editProperty: (id: number | string | undefined) =>
    `${baseURL}/properties/${id}/`,
  deleteProperty: (id: number | string | undefined) =>
    `${baseURL}/properties/${id}/`,
};

export const invalidateMarriageContractQueries = {
  getAssets: () => {
    queryClient
      .invalidateQueries(MarriageContractEndpoints.getAssets())
      .then((r) => r);
  },
  getContractParties: () => {
    queryClient
      .invalidateQueries(MarriageContractEndpoints.getContractParties())
      .then((r) => r);
  },
  getProperties: () => {
    queryClient
      .invalidateQueries(MarriageContractEndpoints.getProperties())
      .then((r) => r);
  },
};

export const useGetAssets = () =>
  useGet<IAssetsList>(MarriageContractEndpoints.getAssets());

export const useAddAsset = () =>
  usePost<IAsset, IAsset>(MarriageContractEndpoints.addAsset());

export const useEditAsset = (id: number | string | undefined) =>
  usePut<IAsset, IAsset>(MarriageContractEndpoints.editAsset(id));

export const useGetContractParties = () =>
  useGet<ContractParties>(MarriageContractEndpoints.getContractParties());

export const useDeleteAsset = (id: number | string | undefined) =>
  useDelete(MarriageContractEndpoints.deleteAsset(id));

export const useGetProperties = () =>
  useGet<IPropertiesList>(MarriageContractEndpoints.getProperties());

export const useAddProperty = () =>
  usePost<IProperty, IProperty>(MarriageContractEndpoints.addProperty());

export const useEditProperty = (id: number | string | undefined) =>
  usePut<IProperty, IProperty>(MarriageContractEndpoints.editProperty(id));

export const useDeleteProperty = (id: number | string | undefined) =>
  useDelete(MarriageContractEndpoints.deleteProperty(id));
